import React, { useState } from "react";
import {
  IonButton,
  IonRow,
  IonCol,
  IonGrid,
  IonContent,
  IonCard,
  IonText,
} from "@ionic/react";
import moment from "moment";
import emailjs from "emailjs-com";
import { useHistory } from "react-router-dom";
import { useMenu } from "../context/MenuContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReviewStep from "../components/Layout/Steps/ReviewStep";
import MenuSelectionStep from "../components/Layout/Steps/MenuSelectionStep";
import PickupInfoStep from "../components/Layout/Steps/PickupInfoStep";
import BasicInfoStep from "../components/Layout/Steps/BasicInfoStep";
import CateringInfoStep from "../components/Layout/Steps/CateringInfoStep"; // New step for catering info

const Preorder = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedDate, setSelectedDate] = useState(null); // Manage selectedDate here
  const [selectedItems, setSelectedItems] = useState([]); // Store selected cart items

  const [categories, setCategories] = useState([
    {
      key: Date.now(),
      items: [{ key: Date.now(), value: "", quantity: 1 }],
      selectedCategory: "",
    },
  ]);
  const [formData, setFormData] = useState({
    selectedItems: [],
  });
  const [errors, setErrors] = useState({}); // To manage validation errors
  const history = useHistory();
  const { cateringMenu, loading, error } = useMenu();

  // Next and Previous Handlers
  const next = () => {
    if (validateStep(currentStep)) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  // Validate each step
  const validateStep = (step) => {
    let isValid = true;
    const newErrors = {};

    switch (step) {
      case 0: // Basic Info Step
        if (!formData.orderName) {
          newErrors.orderName = "Order Name is required";
          toast.error("Order Name is required");
          isValid = false;
        }
        if (!formData.phoneNumber) {
          newErrors.phoneNumber = "Phone Number is required";
          toast.error("Phone Number is required");
          isValid = false;
        }
        if (!formData.email) {
          newErrors.email = "Email is required";
          toast.error("Email is required");
          isValid = false;
        }
        if (!formData.serviceType) {
          newErrors.serviceType = "Service type is required";
          toast.error("Service type is required");
          isValid = false;
        }
        break;

      case 1: // Pickup or Catering Info Step
        if (formData.serviceType === "pickup") {
          if (!selectedDate) {
            newErrors.selectedDate = "Pickup date is required";
            toast.error("Pickup date is required");
            isValid = false;
          }
          if (!formData.pickupTime) {
            newErrors.pickupTime = "Pickup time is required";
            toast.error("Pickup time is required");
            isValid = false;
          }
          if (!formData.tempType) {
            newErrors.tempType = "Temperature preference is required";
            toast.error("Temperature preference is required");
            isValid = false;
          }
        } else if (formData.serviceType === "catering") {
          if (!selectedDate) {
            newErrors.selectedDate = "Pickup date is required";
            toast.error("Pickup date is required");
            isValid = false;
          }
          if (!formData.pickupTime) {
            newErrors.pickupTime = "Pickup time is required";
            toast.error("Pickup time is required");
            isValid = false;
          }
        }
        break;

      case 2: // Menu Selection Step
        if (selectedItems.length === 0) {
          newErrors.items = "At least one item must be selected";
          toast.error("At least one item must be selected");
          isValid = false;
        } else if (
          !selectedItems.every((item) => item.name && item.quantity > 0)
        ) {
          newErrors.items =
            "Every selected item must have a valid name and quantity greater than 0";
          toast.error(
            "Every selected item must have a valid name and quantity greater than 0"
          );
          isValid = false;
        }
        break;

      default:
        break;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Submit Order
  const handleSubmit = () => {
    if (!validateStep(currentStep)) return;

    const templateParams = {
      orderName: formData.orderName,
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      pickupDate: selectedDate ? moment(selectedDate).format("MMMM D, YYYY") : undefined,
      pickupTime: formData.pickupTime,
      tempType: formData.tempType,
      serviceType: formData.serviceType, // Add serviceType here
      categories: formData.selectedItems.map((item) => ({
        category: item.category,
        items: `${item.name} (x${item.quantity})`,
      })),
      comments: formData.comments,
    };
    

    emailjs
      .send(
        "service_marcellas",
        "template_mi55j1p",
        templateParams,
        "OXVmMrXHHOEpr832j"
      )
      .then(
        (response) => {
          alert("Order submitted successfully!");
          resetForm();
          history.push("/");
        },
        (err) => {
          alert("Failed to submit order.");
        }
      );
  };

  const resetForm = () => {
    setCurrentStep(0);
    setSelectedDate(null);
    setCategories([
      {
        key: Date.now(),
        items: [{ key: Date.now(), value: "", quantity: 1 }],
        selectedCategory: "",
      },
    ]);
    setFormData({});
  };

  // Define Steps
  const steps = [
    {
      title: "Basic",
      content: (
        <BasicInfoStep
          formData={formData}
          setFormData={setFormData}
          errors={errors}
        />
      ),
    },
    {
      title: formData.serviceType === "pickup" ? "Pickup" : "Catering",
      content:
        formData.serviceType === "Pickup" ? (
          <PickupInfoStep
            formData={formData}
            setFormData={setFormData}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            errors={errors}
          />
        ) : (
          <CateringInfoStep
            formData={formData}
            setFormData={setFormData}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            errors={errors}
          />
        ),
    },
    {
      title: "Menu",
      content: (
        <MenuSelectionStep
          cateringMenu={cateringMenu}
          categories={categories}
          setCategories={setCategories}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setFormData={setFormData}
          loading={loading}
          error={error}
          errors={errors}
        />
      ),
    },
    {
      title: "Review",
      content: (
        <ReviewStep
          selectedItems={selectedItems}
          formData={formData}
          setFormData={setFormData}
        />
      ),
    },
  ];

  return (
    <IonContent color="light" scroll-y="false">
      <IonGrid className="p-0 m-0 h-full md:py-2 relative">
        <IonCard className="h-full m-0 max-w-3xl mx-auto md:rounded-xl">
          {/* Header for steps */}
          <IonRow className="bg-slate-50 border-b border-slate-200 px-4 ion-justify-content-between ion-align-items-center h-[8%]">
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <IonCol
                  size="auto"
                  className={`p-0 ${
                    currentStep === index ? "active" : "hidden"
                  }`}
                >
                  {currentStep === index && (
                    <IonText className="text-mRed font-bold font-3xl">
                      {/* <h2 className="text-3xl">{step.title}</h2> */}
                      <h2 className="text-xl">
                        Preorder
                        <span className="text-sm pl-2 text-gray-800">
                          Step: {index + 1}
                        </span>
                      </h2>
                    </IonText>
                  )}
                </IonCol>
              </React.Fragment>
            ))}
          </IonRow>

          {/* Content for the current step */}
          <div className="h-[84%] ion-align-items-center ion-justify-content-center relative">
            <ToastContainer
              autoClose={3000}
              className="absolute top-30 left-1/2 z-20"
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />

            {steps[currentStep].content}
          </div>

          {/* Footer for navigation buttons */}
          <IonRow className="ion-align-items-center ion-justify-content-center h-[8%] bg-slate-50 flex">
            {currentStep > 0 && (
              <IonCol size="auto">
                <IonButton
                  color="danger"
                  onClick={prev}
                  className="text-white"
                  shape="round"
                  size="small"
                >
                  Back
                </IonButton>
              </IonCol>
            )}

            {currentStep === steps.length - 1 && (
              <IonCol size="auto">
                <IonButton
                  color="primary"
                  onClick={handleSubmit}
                  shape="round"
                  size="small"
                  className="text-white"
                >
                  Order
                </IonButton>
              </IonCol>
            )}

            {currentStep < steps.length - 1 && (
              <IonCol size="auto">
                <IonButton
                  color="primary"
                  onClick={next}
                  shape="round"
                  size="small"
                >
                  Next
                </IonButton>
              </IonCol>
            )}
          </IonRow>
        </IonCard>
      </IonGrid>
    </IonContent>
  );
};

export default Preorder;
