import React, { useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  IonText,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonItem,
} from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

const PickupInfoStep = ({
  formData,
  setFormData,
  selectedDate, // Receive from parent
  setSelectedDate, // Receive from parent
}) => {
  const [minDate, setMinDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
    const calculateCutoffDate = () => {
      const today = new Date();
      let cutoffDate = new Date(today);
      let day = cutoffDate.getDay();

      if (day === 0) {
        cutoffDate.setDate(cutoffDate.getDate() + 4); // Move to Thursday
      } else if (day === 6) {
        cutoffDate.setDate(cutoffDate.getDate() + 5); // Move to Thursday
      } else {
        cutoffDate.setDate(cutoffDate.getDate() + 2);
        let newDay = cutoffDate.getDay();
        if (newDay === 0) {
          cutoffDate.setDate(cutoffDate.getDate() + 3); // Skip Sunday, Monday to Thursday
        } else if (newDay === 1) {
          cutoffDate.setDate(cutoffDate.getDate() + 2); // Skip Monday to Thursday
        }
      }

      setMinDate(cutoffDate);
    };

    calculateCutoffDate();
  }, []);

  useEffect(() => {
    const getNext7Days = () => {
      const days = [];
      for (let i = 0; i < 7; i++) {
        const day = moment(minDate).add(i, "days");
        if (day.day() !== 0 && day.day() !== 1) {
          days.push(day.format("MMMM D, YYYY"));
        }
      }
      setAvailableDates(days);
    };

    if (minDate) {
      getNext7Days();
    }
  }, [minDate]);

  // Updated handleDateChange
  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("MMMM D, YYYY");

    // Check if the selected date from the calendar is in the available dates
    if (!availableDates.includes(formattedDate)) {
      setAvailableDates([...availableDates, formattedDate]); // Add it to the list of available dates
    }

    setSelectedDate(date); // Update the selected date

    // Add selectedDate to formData
    setFormData((prevData) => ({
      ...prevData,
      pickupDate: formattedDate, // Add formatted date to formData
    }));

    setShowCalendar(false);
  };

  // Updated handleDropdownChange
  const handleDropdownChange = (value) => {
    const date = moment(value, "MMMM D, YYYY").toDate();
    setSelectedDate(date);

    // Add selectedDate to formData
    setFormData((prevData) => ({
      ...prevData,
      pickupDate: value, // Store selected date in formData
    }));
  };

  return (
    <IonRow className="h-full w-full overflow-auto">
      <IonCol size="12">
        <IonCard className="shadow-none">
          <IonCardHeader className="">
            <IonCardSubtitle>In store</IonCardSubtitle>
            <IonCardTitle>Pickup</IonCardTitle>
          </IonCardHeader>

          <IonList className="w-full">
            <IonRow className="">
              <IonCol size="12" className="text-left text-gray-600 px-4">
                <IonText>
                  Pickups are in store, please complete the pickup details for
                  your order. Our pickups are for dates at least 48 hours in
                  advanced
                </IonText>
              </IonCol>
            </IonRow>
            {showCalendar && (
              <IonCol size="12" sizeMd="6">
                <Calendar
                  onChange={handleDateChange} // Call handleDateChange when selecting a date
                  value={selectedDate}
                  minDate={minDate}
                  className="text-mGreen mx-auto border-4 drop-shadow-md border-mGreen p-2"
                  tileDisabled={({ date }) => {
                    const day = date.getDay();
                    return day === 0 || day === 1; // Disable Sundays and Mondays
                  }}
                />
              </IonCol>
            )}

            <IonRow className="w-full">
              <IonCol size="10">
                <IonItem lines="none">
                  <IonSelect
                    label="Date for Pickup"
                    labelPlacement="floating"
                    className="rounded-md bg-slate-100 text-slate-800 px-2"
                    onIonChange={(e) => handleDropdownChange(e.detail.value)} // Call handleDropdownChange
                    value={
                      selectedDate
                        ? moment(selectedDate).format("MMMM D, YYYY")
                        : undefined
                    }
                  >
                    {availableDates.map((day, index) => (
                      <IonSelectOption key={index} value={day}>
                        {day}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>

              <IonCol size="2" className="ion-align-self-end flex justify-end">
                <IonButton
                  size="small"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <IonIcon icon={calendarOutline}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonItem lines="none">
                  <IonSelect
                    labelPlacement="floating"
                    value={formData.pickupTime}
                    label="Pickup Time"
                    className="bg-slate-100 text-slate-800 px-2 rounded-md"
                    onIonChange={(e) =>
                      setFormData({ ...formData, pickupTime: e.detail.value })
                    }
                  >
                    <IonSelectOption value="10am">10am</IonSelectOption>
                    <IonSelectOption value="11am">11am</IonSelectOption>
                    <IonSelectOption value="2pm">2pm</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <IonItem lines="none">
                  <IonSelect
                    value={formData.tempType}
                    labelPlacement="floating"
                    label="Food Temp"
                    className="bg-slate-100 text-slate-800 px-2 rounded-md"
                    onIonChange={(e) =>
                      setFormData({ ...formData, tempType: e.detail.value })
                    }
                  >
                    <IonSelectOption value="Cold">Cold</IonSelectOption>
                    <IonSelectOption value="Hot">Hot</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonList>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default PickupInfoStep;
