import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import lunch1 from "../../assets/marcellas_menu_2023-1.png";
import lunch2 from "../../assets/marcellas_menu_2023-2.png";
import catering1 from "../../assets/catering-menu-1.png";
import catering2 from "../../assets/catering-menu-2.png";
import { IonButton, IonModal, IonIcon } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

function ImageSlider({ menuType }) {  // Destructure the menuType prop here
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Array of images based on the current menu type
  const images =
    menuType === "lunch" ? [lunch1, lunch2] : [catering1, catering2];

  // Function to open the lightbox
  const openLightbox = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  // Function to close the lightbox
  const closeLightbox = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        className="drop-shadow-lg"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div>
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                style={{ width: "100%", height: "100%" }}
                onClick={() => openLightbox(image)} // Open lightbox on click
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* Lightbox Modal */}
      <IonModal isOpen={isModalOpen} onDidDismiss={closeLightbox}>
        <div className="relative flex justify-center items-center w-full h-full bg-slate-100 p-4">
          <IonButton
            onClick={closeLightbox}
            color="danger"
            size="large"
            className="absolute bottom-4 right-4 z-10"
          >
            <IonIcon slot="icon-only" icon={closeCircleOutline} size="large" />
          </IonButton>
          
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full h-auto rounded-md"
            />
          )}

        </div>
      </IonModal>
    </>
  );
}

export default ImageSlider;
