import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { closeCircle, closeOutline, removeCircleOutline } from "ionicons/icons";
import React from "react";

function Cart({
  selectedItems,
  setShowCartModal,
  updateItemQuantity,
  removeSelectedItem,
}) {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Your Cart</IonTitle>
          <IonButton
            slot="end"
            fill="clear"
            onClick={() => setShowCartModal(false)}
          >
            <IonIcon icon={closeOutline} />
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRow className="h-full">
          <IonCol size="12">
            <IonCard className="ion-no-margin py-8 shadow-none">
              <IonCardHeader>
                <IonCardTitle>Items - {selectedItems.length}</IonCardTitle>
              </IonCardHeader>
              <IonList>
                {selectedItems.length > 0 ? (
                  selectedItems.map((item) => (
                    <IonItem key={item.id}>
                      <IonLabel className="w-full">
                        {item.name} - ${item.price}
                      </IonLabel>

                      {/* Quantity Input in Modal */}
                      <IonInput
                        type="number"
                        value={item.quantity}
                        min="1"
                        className="w-20"
                        onIonChange={(e) =>
                          updateItemQuantity(item.id, parseInt(e.detail.value))
                        }
                      />

                      {/* Delete Button in Modal */}
                      <IonButton
                        color="danger"
                        fill="clear"
                        onClick={() => removeSelectedItem(item.id)}
                      >
                        <IonIcon icon={removeCircleOutline} />
                      </IonButton>
                    </IonItem>
                  ))
                ) : (
                  <IonItem>
                    <IonLabel>No items in the cart</IonLabel>
                  </IonItem>
                )}
              </IonList>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonRow>
            <IonCol size="auto" className="mx-auto p-0">
              <IonButton
                color="danger"
                size="small"
                onClick={() => setShowCartModal(false)}
              >
                <IonIcon icon={closeCircle}></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </>
  );
}

export default Cart;
