import {
  IonCol,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
  IonList,
  IonListHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonButton,
  IonIcon,
  IonBadge,
  IonModal,
} from "@ionic/react";
import { cartOutline } from "ionicons/icons";
import React, { useState } from "react";
import Cart from "../../Cart";

const ReviewStep = ({ selectedItems, setSelectedItems, formData, setFormData }) => {
  // Function to calculate the total for a specific item
  const calculateItemTotal = (price, quantity) => {
    return parseFloat(price) * quantity;
  };

  // Function to calculate the total including tax
  const calculateTotalWithTax = (total) => {
    return total * 1.0725; // Assuming a tax rate of 7.25%
  };

  const [showCartModal, setShowCartModal] = useState(false); // Control the cart modal visibility
  // Handle removing an item from the selected items list
  const removeSelectedItem = (id) => {
    // Update selectedItems state by filtering out the removed item
    const updatedItems = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(updatedItems);

    // Also update the formData to remove the deleted item
    setFormData((prevData) => ({
      ...prevData,
      selectedItems: updatedItems, // Update formData with the filtered items
    }));
  };
  const updateItemQuantity = (id, newQuantity) => {
    setSelectedItems(
      selectedItems.map((item) =>
        item.id === id ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  let grandTotal = 0; // To accumulate the total
  console.log(formData);

  return (
    <IonRow className="h-full w-full">
      <IonCol size="12" className="h-full overflow-auto">
        <IonCard className="shadow-none">
          <IonCardHeader className="">
            <IonCardSubtitle className="capitalize">
              {formData.serviceType}
            </IonCardSubtitle>
            <IonCardTitle className="capitalize">Order Summary</IonCardTitle>
            <div className="absolute right-0 top-0">
              <IonButton
                size="large"
                shape="round"
                color="tertiary"
                onClick={() => setShowCartModal(true)}
              >
                <IonIcon
                  slot="icon-only"
                  color="dark"
                  icon={cartOutline}
                ></IonIcon>
              </IonButton>
              <IonBadge
                shape="rounded"
                className="absolute right-0 top-0 rounded-full"
              >
                {selectedItems.length}
              </IonBadge>
            </div>
          </IonCardHeader>
          <IonList className="border bg-gray-50 w-full">
            <IonListHeader className="font-bold mb-2 text-center">
              Contact Details
            </IonListHeader>
            {/* Displaying Form Data */}
            <IonItem>
              {formData.orderName && <li>Order Name:{formData.orderName}</li>}
            </IonItem>
            <IonItem>
              {formData.phoneNumber && (
                <li>Phone Number: {formData.phoneNumber}</li>
              )}
            </IonItem>
            <IonItem>
              {formData.email && <li>Email: {formData.email}</li>}
            </IonItem>
          </IonList>

          <IonList className="border bg-gray-50 w-full">
            <IonListHeader className="font-bold mb-2 text-center">
              Pickup Details
            </IonListHeader>
            <IonItem>
              {formData.pickupTime && (
                <span>Pickup Time: {formData.pickupTime}</span>
              )}
            </IonItem>

            {formData.tempType && (
              <IonItem>
                <span>Temperature: {formData.tempType}</span>
              </IonItem>
            )}

            <IonItem>
              {formData.pickupDate && (
                <span>Pickup Date: {formData.pickupDate}</span>
              )}
            </IonItem>
          </IonList>

          <IonList className="border bg-gray-50 w-full">
            <IonListHeader className="font-bold mb-2 text-center">
              Cart Items
            </IonListHeader>
            {selectedItems.map((item) => {
              const itemTotal = calculateItemTotal(item.price, item.quantity);
              grandTotal += itemTotal; // Add to grand total

              return (
                <IonItem key={item.id} className="">
                  {item.name} (x{item.quantity}) - ${itemTotal.toFixed(2)}
                </IonItem>
              );
            })}
          </IonList>

          <IonList className="border w-full">
            <IonListHeader className="text-center flex flex-col py-4">
              <h2 className="font-bold">Subtotal: ${grandTotal.toFixed(2)}</h2>
              <h4 className="font-bold text-lg">
                Total w/ Tax: ${calculateTotalWithTax(grandTotal).toFixed(2)}
              </h4>
            </IonListHeader>
            <IonItem lines="none" className="p-2">
              <IonLabel position="floating">Additional Comments</IonLabel>
              <IonTextarea
                placeholder="Any additional notes for order"
                className="bg-white"
                value={formData.comments || ""}
                onIonChange={(e) =>
                  setFormData({ ...formData, comments: e.detail.value })
                }
              />
            </IonItem>
          </IonList>
        </IonCard>

        <IonModal
          isOpen={showCartModal}
          onDidDismiss={() => setShowCartModal(false)}
        >
          <Cart
            selectedItems={selectedItems}
            setShowCartModal={setShowCartModal}
            updateItemQuantity={updateItemQuantity}
            removeSelectedItem={removeSelectedItem}
          />
        </IonModal>
      </IonCol>
    </IonRow>
  );
};

export default ReviewStep;
