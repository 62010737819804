import React, { useState } from "react";
import { IonButton, IonCol, IonContent, IonGrid, IonRow, IonText } from "@ionic/react";
import ImageSlider from "../components/Layout/ImageSlider";

function Menu() {
  const [menuType, setMenuType] = useState("lunch");

  return (
    <IonContent>
      <IonGrid className="flex flex-col justify-center h-full bg-light bg-slate-100">
        <IonRow className="rounded-sm">
          <IonCol size="12" className="mx-auto">
            <ImageSlider menuType={menuType} />
          </IonCol>
          <IonCol size="12" className="text-center">
            <IonText className="font-bold text-2xl">
              {" "}
              {menuType === "lunch" ? "Lunch Menu" : "Catering Menu"}{" "}
            </IonText>
          </IonCol>

          <IonCol size="auto" className="mx-auto">
          <IonButton
            color="tertiary"
            size="small"
            onClick={() =>
              setMenuType((prevMenuType) =>
                prevMenuType === "lunch" ? "catering" : "lunch"
              )
            }
          >
            {menuType === "lunch"
              ? "Catering Menu"
              : "Lunch Menu"}
          </IonButton>
        </IonCol>
        </IonRow>

      </IonGrid>
    </IonContent>
  );
}

export default Menu;
