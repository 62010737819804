import React, { useEffect, useState } from "react";
import {
  IonGrid,
  IonRow,
  IonCol,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  IonText,
  IonList,
  IonListHeader,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from "@ionic/react";
import { calendarOutline } from "ionicons/icons";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

const CateringInfoStep = ({
  formData,
  setFormData,
  selectedDate,
  setSelectedDate,
}) => {
  const [minDate, setMinDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
    const calculateMinDate = () => {
      const today = new Date();
      let cutoffDate = new Date(today);
      const day = today.getDay();

      // If it's Saturday or Sunday, skip to Tuesday (as Sunday and Monday are closed)
      if (day === 0) {
        cutoffDate.setDate(today.getDate() + 3);
      } else if (day === 6) {
        cutoffDate.setDate(today.getDate() + 4);
      } else {
        cutoffDate.setDate(today.getDate() + 2);
        const newDay = cutoffDate.getDay();
        if (newDay === 0) cutoffDate.setDate(cutoffDate.getDate() + 2);
        else if (newDay === 1) cutoffDate.setDate(cutoffDate.getDate() + 1);
      }
      setMinDate(cutoffDate);
    };
    calculateMinDate();
  }, []);

  useEffect(() => {
    const getNextAvailableDates = () => {
      const days = [];
      for (let i = 0; i < 7; i++) {
        const day = moment(minDate).add(i, "days");
        if (day.day() !== 0 && day.day() !== 1) {
          days.push(day.format("MMMM D, YYYY"));
        }
      }
      setAvailableDates(days);
    };

    if (minDate) getNextAvailableDates();
  }, [minDate]);

  const handleDateChange = (date) => {
    const formattedDate = moment(date).format("MMMM D, YYYY");

    // Check if selected date is in the available dates
    if (!availableDates.includes(formattedDate)) {
      setAvailableDates([...availableDates, formattedDate]);
    }
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const handleDropdownChange = (value) => {
    const date = moment(value, "MMMM D, YYYY").toDate();
    setSelectedDate(date);
  };

  return (
    <IonRow className="overflow-auto w-full h-full">
      <IonCol size="12">
        <IonCard className="shadow-none">
          <IonCardHeader className="">
            <IonCardSubtitle>Local Gems</IonCardSubtitle>
            <IonCardTitle>Catering</IonCardTitle>
          </IonCardHeader>

          <IonList className="w-full">
            <IonRow>
              <IonCol size="12" className="text-left text-gray-600 px-4">
                <IonText>
                  Catering is done through a 3rd Party small buisness, please
                  contact,
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              {showCalendar && (
                <IonCol size="12" sizeMd="6">
                  <Calendar
                    onChange={handleDateChange}
                    value={selectedDate}
                    minDate={minDate}
                    className="text-mGreen mx-auto border-4 drop-shadow-md border-mGreen p-2"
                    tileDisabled={({ date }) => {
                      const day = date.getDay();
                      return day === 0 || day === 1; // Disable Sundays and Mondays
                    }}
                  />
                </IonCol>
              )}
            </IonRow>
            <IonRow>
              <IonCol size="10">
                <IonSelect
                  label="Catering Date"
                  labelPlacement="floating"
                  className="rounded-md bg-slate-100 text-slate-800 px-2"
                  onIonChange={(e) => handleDropdownChange(e.detail.value)}
                  value={
                    selectedDate
                      ? moment(selectedDate).format("MMMM D, YYYY")
                      : undefined
                  }
                >
                  {availableDates.map((day, index) => (
                    <IonSelectOption key={index} value={day}>
                      {day}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>

              <IonCol size="2" className="ion-align-self-end flex justify-end">
                <IonButton onClick={() => setShowCalendar(!showCalendar)}>
                  <IonIcon size="small" icon={calendarOutline}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="12" sizeMd="6">
                <IonSelect
                  labelPlacement="floating"
                  value={formData.pickupTime}
                  label="Pickup Time"
                  className="bg-slate-100 text-slate-800 px-2 rounded-md"
                  onIonChange={(e) =>
                    setFormData({ ...formData, pickupTime: e.detail.value })
                  }
                >
                  <IonSelectOption value="10am">10am</IonSelectOption>
                  <IonSelectOption value="11am">11am</IonSelectOption>
                  <IonSelectOption value="2pm">2pm</IonSelectOption>
                </IonSelect>
              </IonCol>

              {/* <IonCol size="12" sizeMd="6">
                <IonSelect
                  value={formData.tempType}
                  labelPlacement="floating"
                  label="Food Temp"
                  className="bg-slate-100 text-slate-800 px-2 rounded-md"
                  onIonChange={(e) =>
                    setFormData({ ...formData, tempType: e.detail.value })
                  }
                >
                  <IonSelectOption value="Cold">Cold</IonSelectOption>
                  <IonSelectOption value="Hot">Hot</IonSelectOption>
                </IonSelect>
              </IonCol> */}
            </IonRow>
          </IonList>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default CateringInfoStep;
