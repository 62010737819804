import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  IonButton,
  IonCol,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonTextarea,
  IonText,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonFooter,
} from "@ionic/react";

function Contact({ setShowContactModal }) {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_3salaz",
        "template_4y4xx4h",
        form.current,
        "OXVmMrXHHOEpr832j"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset(); // Reset the form fields
          toast.success("Your message has been sent successfully!");
          setShowContactModal(false); // Close the modal after sending the email
        },
        (error) => {
          console.log(error.text);
          toast.error(
            "There was an error sending your message. Please try again."
          ); // Show error toast notification
        }
      );
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Contact Us</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="text-center">
      <ToastContainer
          // position="top-center" // Keep the toast position centered
          autoClose={3000}
          className="absolute top-30 left-1/2 z-20"
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <IonGrid className="bg-mYellow h-full">
          <IonRow className="ion-justify-content-center h-full w-full">
            <IonCol size="12">
              <IonText>
                <p className="text-sm">
                  Bringing a large group? Looking to collab on our social media?
                  Maybe you just want to send some love to the crew! Whatever it
                  is, just send us a message and we'll get back to you as soon
                  as we can.
                </p>
              </IonText>
            </IonCol>

            <form ref={form} onSubmit={sendEmail} className="container mx-auto">
              <IonCol size="6" className="w-full h-full">
                <IonItem className="p-0 m-0">
                  <IonLabel position="stacked">Name</IonLabel>
                  <IonInput name="user_name" required />
                </IonItem>
              </IonCol>

              <IonCol size="6">
                <IonItem className="p-0 m-0">
                  <IonLabel position="stacked">Email</IonLabel>
                  <IonInput type="email" name="user_email" required />
                </IonItem>
              </IonCol>

              <IonCol size="12">
                <IonItem>
                  <IonLabel position="stacked">Subject</IonLabel>
                  <IonInput name="subject" required />
                </IonItem>
              </IonCol>

              <IonCol size="12">
                <IonItem>
                  <IonLabel position="stacked">Your message</IonLabel>
                  <IonTextarea
                    name="message"
                    rows="4"
                    placeholder="Leave a comment..."
                    required
                  />
                </IonItem>
              </IonCol>

              <IonCol size="12" className="ion-text-center ion-margin-top">
                <IonButton type="submit" expand="block" color="primary">
                  Send
                </IonButton>
              </IonCol>
            </form>
          </IonRow>
        </IonGrid>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonRow>
            <IonCol size="6" className="mx-auto">
              <IonButton
                expand="block"
                color="danger"
                onClick={() => setShowContactModal(false)}
              >
                Close
              </IonButton>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </>
  );
}

export default Contact;
