import React from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonText,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
} from "@ionic/react";

const BasicInfoStep = ({ formData, setFormData }) => {
  const [phoneError, setPhoneError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;

    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}`;
    }
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const validatePhoneNumber = (value) => {
    const formattedPhoneNumber = formatPhoneNumber(value);
    const phoneRegex = /^[2-9]\d{2}-\d{3}-\d{4}$/;
    if (!phoneRegex.test(formattedPhoneNumber)) {
      setPhoneError("Invalid phone number format. Expected: XXX-XXX-XXXX");
    } else {
      setPhoneError("");
    }
    setFormData({ ...formData, phoneNumber: formattedPhoneNumber });
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
    setFormData({ ...formData, email: value });
  };

  return (
    <IonRow className="h-full w-full overflow-auto">
      <IonCol size="12">
        <IonCard className="shadow-none">

          <IonCardHeader className="">
            <IonCardSubtitle>Basic Details</IonCardSubtitle>
            <IonCardTitle>Contact</IonCardTitle>
          </IonCardHeader>

          {/* <img
          alt="Silhouette of mountains"
          src="https://ionicframework.com/docs/img/demos/card-media.png"
        /> */}

          <IonList className="w-full h-full">
          <IonRow className="">
              <IonCol size="12" className="text-left text-gray-600 px-4">
                <IonText>
                  Enter your contact info for the order.
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <IonItem>
                  <IonLabel position="stacked">Name</IonLabel>
                  <IonInput
                    value={formData.orderName}
                    onIonChange={(e) =>
                      setFormData({ ...formData, orderName: e.detail.value })
                    }
                  />
                </IonItem>
              </IonCol>
              <IonCol size="12">
                <IonItem>
                  <IonLabel position="stacked">Phone Number</IonLabel>
                  <IonInput
                    value={formData.phoneNumber}
                    onIonChange={(e) => validatePhoneNumber(e.detail.value)}
                    type="tel"
                    inputmode="numeric"
                    pattern="\d*"
                  />
                  {phoneError && (
                    <IonText color="danger">
                      <p>{phoneError}</p>
                    </IonText>
                  )}
                </IonItem>
              </IonCol>
              <IonCol size="12">
                <IonItem>
                  <IonLabel position="stacked">Email</IonLabel>
                  <IonInput
                    value={formData.email}
                    onIonChange={(e) => validateEmail(e.detail.value)}
                    type="email"
                  />
                  {emailError && (
                    <IonText color="danger">
                      <p>{emailError}</p>
                    </IonText>
                  )}
                </IonItem>
              </IonCol>
              <IonCol size="12">
                {/* New Input for Pickup or Catering */}
                <IonItem lines="none" className="py-2">
                  <IonSelect
                    labelPlacement="floating"
                    label="Service Type"
                    value={formData.serviceType}
                    className="bg-slate-100 text-slate-800 px-2 w-full rounded-md"
                    onIonChange={(e) =>
                      setFormData({ ...formData, serviceType: e.detail.value })
                    }
                  >
                    <IonSelectOption value="Pickup">Pickup</IonSelectOption>
                    <IonSelectOption value="Catering" disabled={true}>Catering</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
          </IonList>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default BasicInfoStep;