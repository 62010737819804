import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonIcon,
  IonList,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonBadge,
  IonModal,
} from "@ionic/react";
import {
  add,
  cartOutline,

} from "ionicons/icons";
import { v4 as uuidv4 } from "uuid"; // Using uuid for unique keys
import { toast } from "react-toastify";
import Cart from "../../Cart";

const MenuSelectionStep = ({
  cateringMenu,
  loading,
  error,
  setFormData,
  selectedItems,
  setSelectedItems,
}) => {
  const [currentCategory, setCurrentCategory] = useState(""); // For currently selecting category
  const [currentItem, setCurrentItem] = useState(""); // For currently selecting item
  const [currentQuantity, setCurrentQuantity] = useState(1); // Default quantity is 1
  const [showCartModal, setShowCartModal] = useState(false); // Control the cart modal visibility

  // Handle adding a selected item with quantity to the selectedItems list
  const addSelectedItem = () => {
    if (currentCategory && currentItem && currentQuantity > 0) {
      const selectedMenuItem = cateringMenu[currentCategory].find(
        (menuItem) => menuItem.name === currentItem
      );

      const newItem = {
        id: uuidv4(),
        category: currentCategory,
        name: currentItem,
        quantity: currentQuantity,
        price: selectedMenuItem ? selectedMenuItem.price : 0, // Ensure price is included
      };

      // Update selectedItems state
      setSelectedItems((prevItems) => [...prevItems, newItem]);

      // Also update the formData to store the selected items
      setFormData((prevData) => ({
        ...prevData,
        selectedItems: [...(prevData.selectedItems || []), newItem], // Add the new item to formData
      }));

      // Show success toast notification
      toast.success(`${currentItem} added successfully to the cart!`);

      resetSelections(); // Reset the current item/category selections after adding
    } else {
      // Show failure toast notification if category, item, or quantity is missing/invalid
      toast.error(
        "Please select a valid category, item, and quantity greater than 0"
      );
    }
  };

  // Handle removing an item from the selected items list
  const removeSelectedItem = (id) => {
    // Update selectedItems state by filtering out the removed item
    const updatedItems = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(updatedItems);

    // Also update the formData to remove the deleted item
    setFormData((prevData) => ({
      ...prevData,
      selectedItems: updatedItems, // Update formData with the filtered items
    }));
  };

  const updateItemQuantity = (id, newQuantity) => {
    setSelectedItems(
      selectedItems.map((item) =>
        item.id === id ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  // Reset the current item/category/quantity selections
  const resetSelections = () => {
    setCurrentCategory("");
    setCurrentItem("");
    setCurrentQuantity(1);
  };

  return (
    <IonRow className="h-full w-full overflow-auto">
      {loading && <IonCol>Loading menu...</IonCol>}
      {error && <IonCol>Error loading menu: {error}</IonCol>}
      <IonCol size="12" className="h-full">
        <IonCard className="shadow-none">
          <IonCardHeader className="relative">
            <IonCardSubtitle>
              Current Cart: {selectedItems.length}
            </IonCardSubtitle>
            <IonCardTitle>Add Items</IonCardTitle>
            <div className="absolute right-0 top-0">
              <IonButton
                size="large"
                shape="round"
                color="tertiary"
                onClick={() => setShowCartModal(true)}
              >
                <IonIcon
                  slot="icon-only"
                  color="dark"
                  icon={cartOutline}
                ></IonIcon>
              </IonButton>
              <IonBadge
                shape="rounded"
                className="absolute right-0 top-0 rounded-full"
              >
                {selectedItems.length}
              </IonBadge>
            </div>
          </IonCardHeader>

          <IonList className="">
            <IonRow className="flex flex-col w-full gap-2">
              <IonCol size="12" className="">
                <IonItem lines="none" className="rounded-xl">
                  <IonLabel>Category</IonLabel>
                  <IonSelect
                    value={currentCategory}
                    placeholder="Select Category"
                    className="text-sm bg-slate-100 px-2 rounded-md"
                    onIonChange={(e) => setCurrentCategory(e.detail.value)}
                  >
                    {Object.keys(cateringMenu).map((categoryName) => (
                      <IonSelectOption key={categoryName} value={categoryName}>
                        {categoryName.charAt(0).toUpperCase() +
                          categoryName.slice(1)}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
              {currentCategory && (
                <IonCol size="12">
                  <IonItem lines="none" className="rounded-xl">
                    <IonLabel>Select Item</IonLabel>
                    <IonSelect
                      value={currentItem}
                      placeholder="Select an item"
                      className="text-sm bg-slate-100 px-2 rounded-md"
                      onIonChange={(e) => setCurrentItem(e.detail.value)}
                    >
                      {cateringMenu[currentCategory]
                        .filter((menuItem) => menuItem.live === true) // Only show live items
                        .map((menuItem) => (
                          <IonSelectOption
                            key={menuItem.name}
                            value={menuItem.name}
                          >
                            ${menuItem.price} | {menuItem.name}
                          </IonSelectOption>
                        ))}
                    </IonSelect>
                  </IonItem>
                </IonCol>
              )}
              {/* Only show the add button when both category and item are selected */}
              {currentCategory && currentItem && (
                <IonCol size="auto" className="mx-auto">
                  <IonButton
                    expand="block"
                    size="small"
                    color="tertiary"
                    onClick={addSelectedItem}
                    className="ion-margin-top mx-auto"
                  >
                    <IonIcon color="dark" icon={add} />
                    <IonText color="dark">Add Item</IonText>
                  </IonButton>
                </IonCol>
              )}
            </IonRow>
          </IonList>
          {/* Modal for Cart Items */}
          <IonModal
            isOpen={showCartModal}
            onDidDismiss={() => setShowCartModal(false)}
          >
            <Cart
              selectedItems={selectedItems}
              setShowCartModal={setShowCartModal}
              updateItemQuantity={updateItemQuantity}
              removeSelectedItem={removeSelectedItem}
            />
          </IonModal>
        </IonCard>
      </IonCol>
    </IonRow>
  );
};

export default MenuSelectionStep;
